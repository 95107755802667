import React, { useEffect } from "react"

export const ContactUsForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://js.hsforms.net/forms/v2.js"
    script.async = true
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "26556401",
          formId: "357c7a0b-5a7b-4527-b723-2d28d36aa73c",
          target: "#hubspotFormContainer",
        })
      }
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <script
        type="text/javascript"
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
      ></script>
      <div id="hubspotFormContainer"></div>
    </>
  )
}
