exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-mdx": () => import("./../../../src/pages/blog.mdx" /* webpackChunkName: "component---src-pages-blog-mdx" */),
  "component---src-pages-bolt-farm-mdx": () => import("./../../../src/pages/bolt-farm.mdx" /* webpackChunkName: "component---src-pages-bolt-farm-mdx" */),
  "component---src-pages-brand-guidelines-tsx": () => import("./../../../src/pages/brand-guidelines.tsx" /* webpackChunkName: "component---src-pages-brand-guidelines-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-customer-bookings-tsx": () => import("./../../../src/pages/customer-bookings.tsx" /* webpackChunkName: "component---src-pages-customer-bookings-tsx" */),
  "component---src-pages-digital-intake-forms-tsx": () => import("./../../../src/pages/digital-intake-forms.tsx" /* webpackChunkName: "component---src-pages-digital-intake-forms-tsx" */),
  "component---src-pages-email-sms-waitlist-tsx": () => import("./../../../src/pages/email-sms-waitlist.tsx" /* webpackChunkName: "component---src-pages-email-sms-waitlist-tsx" */),
  "component---src-pages-independent-hotel-show-tsx": () => import("./../../../src/pages/independent-hotel-show.tsx" /* webpackChunkName: "component---src-pages-independent-hotel-show-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inventory-management-tsx": () => import("./../../../src/pages/inventory-management.tsx" /* webpackChunkName: "component---src-pages-inventory-management-tsx" */),
  "component---src-pages-legal-gdpr-privacy-policy-mdx": () => import("./../../../src/pages/legal/gdpr-privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-gdpr-privacy-policy-mdx" */),
  "component---src-pages-legal-pay-terms-mdx": () => import("./../../../src/pages/legal/pay-terms.mdx" /* webpackChunkName: "component---src-pages-legal-pay-terms-mdx" */),
  "component---src-pages-legal-privacy-policy-mdx": () => import("./../../../src/pages/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-mdx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-reporting-analytics-tsx": () => import("./../../../src/pages/reporting-analytics.tsx" /* webpackChunkName: "component---src-pages-reporting-analytics-tsx" */),
  "component---src-pages-sla-mdx": () => import("./../../../src/pages/sla.mdx" /* webpackChunkName: "component---src-pages-sla-mdx" */),
  "component---src-pages-sms-pricing-tsx": () => import("./../../../src/pages/sms/pricing.tsx" /* webpackChunkName: "component---src-pages-sms-pricing-tsx" */),
  "component---src-pages-terminals-tsx": () => import("./../../../src/pages/terminals.tsx" /* webpackChunkName: "component---src-pages-terminals-tsx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-whitepapers-mdx": () => import("./../../../src/pages/whitepapers.mdx" /* webpackChunkName: "component---src-pages-whitepapers-mdx" */),
  "component---src-templates-blog-post-mdx": () => import("./../../../src/templates/blog-post.mdx" /* webpackChunkName: "component---src-templates-blog-post-mdx" */),
  "component---src-templates-whitepaper-mdx": () => import("./../../../src/templates/whitepaper.mdx" /* webpackChunkName: "component---src-templates-whitepaper-mdx" */)
}

